<template>
	<section id="about-me">
		<div class="py-12"></div>

		<v-container class="text-center">
			<h2 class="display-2 font-weight-bold mb-3">{{ titulo }}</h2>

			<v-responsive class="mx-auto mb-8" width="56">
				<v-divider class="mb-1"></v-divider>

				<v-divider></v-divider>
			</v-responsive>

			<v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
				{{ texto }}
			</v-responsive>

			<v-avatar class="elevation-12 mb-12 white" size="128">
				<v-img :src="avatar"></v-img>
			</v-avatar>
		</v-container>

		<div class="py-12"></div>
	</section>
</template>

<script>
export default {
	props: ['titulo', 'texto', 'avatar', 'goto'],
};
</script>

<template>
	<div>
		<Principal v-bind="principal"></Principal>
		<Secundario v-bind="about"></Secundario>
		<Secundario v-bind="about1"></Secundario>
	</div>
</template>

<script>
import Principal from '../components/Principal.vue';
import Secundario from '../components/Secundario.vue';

export default {
	components: {
		Principal,
		Secundario,
	},

	data() {
		return {
			principal: {
				titulo_1: 'Nuestra',
				titulo_2: 'EMPRESA',
				imagen: require('@/assets/entrada.png'),
				goto: '#about-me',
			},
			about: {
				titulo: 'QUIENES SOMOS',
				texto: `Somos una empresa con más de 30 años de experiencia en el área de la industria metal-mecánica.
        Contamos con un equipo conformado por personal calificado siempre dispuesto a atender sus inquietudes y
        resolver sus problemas de manera eficaz. Nuestra planta tiene un área de trabajo de más de 1400 metros cuadrados,
        completamente a su disposición, y cuenta con todos los equipos y herramientas necesarios para desarrollar cualquier
        tipo de trabajo. Nos especializamos en el área de mantenimiento hidráulico para maquinaria pesada y suministramos
        toda clase de repuestos para estos equipos. Contamos con una planta de cromo-duro que nos permite cromar émbolos de
        hasta 8 metros de largo y remanufacturar partes hidráulicas tales como spools de controles,
        camisas hidráulicas y más.`,
				avatar: 'http://dimamsas.com/wp-content/uploads/2019/10/retro-e1570473131268.png',
				goto: '#features',
			},
			about1: {
				titulo: 'NUESTRA MISION',
				texto: `Ofrecer un servicio integral de alta calidad, que incluye distribución de productos, elaboración de repuestos,
				remanufacturación de partes metalmecánicas y actuadores hidráulicos y todo lo relacionado con accesorios en ingeniería mecánica para el diseño,
				selección y fabricación de sistemas, equipos hidráulicos y maquinaria industrial en general.`,
				avatar: 'http://dimamsas.com/wp-content/uploads/2019/10/engranajes.jpg',
				goto: '#features',
			},
		};
	},
};
</script>
<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
